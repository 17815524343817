<template>
  <div
    class="radio-tab-wrap"
    :class="[isActive ? 'active' : '', isDisabled ? 'disabled' : '']"
    @click="$emit('click', text)"
  >
    <span
      :data-tool-tip="!hideTooltipIcon ? '' : infoText"
      class="radio-tab-wrap-text"
      :class="!hideTooltipIcon ? '' : 'intip4'"
    >
      {{ text }}
    </span>
    <span v-if="infoText" :data-tool-tip="infoText" class="intip4">
      <font-awesome-icon v-if="!hideTooltipIcon" :icon="['fas', 'info-circle']" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: 'sample',
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    infoText: {
      type: String,
      required: false,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideTooltipIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.light-theme {
  .radio-tab-wrap.active {
    font-weight: 600;
  }
}
.radio-tab-wrap {
  display: inline-block;
  padding: 5px 6px;
  color: #687982;
  text-transform: uppercase;
  background-color: #7878783d;
  letter-spacing: 0.05em;
  cursor: pointer;
  border: 1px solid rgba(170, 170, 170, 0.2);
  border-radius: 4px 4px 4px 4px;

  &:not(:last-child) {
    border-radius: 4px 0px 0px 4px;
  }

  &:not(:first-child) {
    border-radius: 0 4px 4px 0;
  }

  &.active {
    font-weight: 600;
    color: var(--buttontext);
    background-color: var(--primarycolor);
    border-color: var(--primarycolor);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

.intip4 {
  position: relative;
  top: 0;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 210px;
  padding: 10px 12px;
  margin-left: -50px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: left;
  text-transform: none;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
.intip4[data-tool-tip]::after {
  // width: 155px;
  margin-left: -108px;
  font-size: 12px;
  font-weight: 400;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 1);
}
.intip4[data-tool-tip]:hover::after {
  bottom: 183%;
  transform: scale(1);
}
.radio-tab-wrap-text {
  font-size: 16px !important;
}
</style>
